const ENDPOINTS = {
  AUTH_USER: '/user',
  PROFILE: '/profile',
  LINEAR: '/linear',
  MEDIA: '/media',
  GENRE: '/genre',
  CHANNEL: '/channel',
  MISC: '/misc',
  USER: '/user',
  SUBSCRIPTION: '/subscription',
  OFFERS: '/offer',
  LANDINGPAGE: '/landingpage',
  STATS: '/stats',
  VOUCHER_SERVICE: '/voucherservice',
  PLACEHOLDER: '/placeholder',
};

export default ENDPOINTS;
