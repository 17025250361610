import { useState } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { ErrorData } from 'types/Common';

const useAxiosRequest = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<number | undefined>(undefined);
  const [errorData, setErrorData] = useState<Array<ErrorData>>();
  const [axiosError, setAxiosError] = useState<AxiosError>();

  const requestFunc = async <T,>(apiRequest: T): Promise<T | undefined> => {
    try {
      setIsLoading(true);
      return await apiRequest;
    } catch (e) {
      if (e instanceof AxiosError) {
        setAxiosError(e);

        setIsError((prevState) => !prevState);
        setErrorData(e.response?.data);
        setError(e?.response?.status);

        if (e.response?.status && e.response.status < 400 && e.response.status >= 404) {
          enqueueSnackbar(
            e.response?.data.message || e.response?.data.details
              ? e.response?.data.message || e.response?.data.details
              : (e as AxiosError).message,
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          );
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { requestFunc, error, errorData, isLoading, setIsError, isError, axiosError };
};

export default useAxiosRequest;
