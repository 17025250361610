export const PROFILE_LINK = '/profile';

const appLinks = {
  placeholder: {
    link: '/placeholder',
  },
  home: {
    link: '/',
  },
  landingMedia: {
    link: '/media/:media_id',
    asLink: (media_id: number | string): string => `/media/${media_id}`,
  },
  thankYou: {
    link: '/thank-you',
  },
  appThankYou: {
    link: '/app-thank-you',
  },
  error: {
    link: '/error',
  },
  login: {
    link: '/login',
    asLink: (vid: string, type: string): string => `/login?v=${vid}&type=${type}`,
  },
  forgotPassword: {
    name: 'Forgot password',
    link: '/forgot-password',
  },
  changePassword: {
    name: 'Change password',
    link: '/change-password',
  },
  newPassword: {
    link: '/new-password',
  },
  profiles: {
    link: '/profiles',
  },
  newProfile: {
    link: '/profiles/new',
  },
  manageProfiles: {
    link: '/profiles/manage',
  },
  updateProfile: {
    link: '/profiles/manage/:id',
    asLink: (id: number | string): string => `/profiles/manage/${id}`,
  },
  invitation: {
    link: '/invitation/:id',
    asLink: (id: number | string): string => `/invitation/${id}`,
  },
  register: {
    name: 'Registration',
    link: '/registration',
    asLink: (vid: string, type: string): string => `/registration?v=${vid}&type=${type}`,
  },
  registerReceipt: {
    name: 'Registration receipt',
    link: '/registration/receipt',
  },
  registerPayment: {
    name: 'Registration payment',
    link: '/registration/payment',
  },
  externalReceipt: {
    name: 'Registration payment',
    link: '/registration/receipt/external',
  },
  logout: {
    link: '/logout',
  },
  profile: {
    link: '/profile',
  },
  discover: {
    link: `${PROFILE_LINK}/discover`,
    route: 'discover',
  },
  watchlist: {
    link: `${PROFILE_LINK}/watchlist`,
    route: 'watchlist',
  },
  tvGuide: {
    link: `${PROFILE_LINK}/guide`,
    route: 'guide',
  },
  tvFriends: {
    link: `${PROFILE_LINK}/tvfriends`,
  },
  liveTV: {
    name: 'Live TV',
    route: 'live',
    link: `${PROFILE_LINK}/live`,
  },
  genres: {
    link: `${PROFILE_LINK}/genres`,
    route: 'genres',
  },
  settings: {
    link: `${PROFILE_LINK}/settings`,
  },
  search: {
    link: `${PROFILE_LINK}/search`,
    route: 'search',
  },
  services: {
    link: `${PROFILE_LINK}/services`,
  },
  noServices: {
    link: `${PROFILE_LINK}/no-services`,
  },
  offers: {
    link: `${PROFILE_LINK}/offers`,
    route: 'offers',
  },
  media: {
    link: `${PROFILE_LINK}/media`,
  },
  accountDetails: {
    link: `${PROFILE_LINK}/details`,
  },
  passwordDetail: {
    link: `${PROFILE_LINK}/details/password`,
  },
  singleMedia: {
    link: `${PROFILE_LINK}/media/:id`,
    asLink: (id: number | string, channelID: number): string =>
      `${PROFILE_LINK}/media/${id}?channelID=${channelID}`,
  },
  invite: {
    link: '/invite',
  },
  inviteMedia: {
    link: '/invite/media/:id',
  },
  watchShows: {
    link: '/watch/shows/:id',
  },
  watchMovies: {
    link: '/watch/movies/:id',
  },
  qrpPayment: {
    link: '/qrp/payment',
  },
  qrpThankYou: {
    link: '/qrp/thank-you',
  },
  tric: {
    link: '/tric',
    name: 'TRIC',
    route: 'tric',
  },
  support: {
    name: 'Support',
    link: '/support',
  },
  availableStreamingServices: {
    name: 'Support',
    link: '/available-streaming-services',
  },
};

const appPages = [
  appLinks.login,
  appLinks.register,
  appLinks.forgotPassword,
  appLinks.changePassword,
  appLinks.profiles,
  appLinks.newProfile,
  appLinks.manageProfiles,
  appLinks.tvGuide,
  appLinks.discover,
  appLinks.watchlist,
  appLinks.accountDetails,
  appLinks.liveTV,
  appLinks.tvFriends,
  appLinks.search,
  appLinks.settings,
  appLinks.genres,
  appLinks.offers,
  appLinks.support,
];

export { appPages, appLinks };
