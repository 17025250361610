import { AppBar, Theme, Grid, IconButton, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';

import NavbarDropdown from './DropdownMenu';
import SetBackgroundOnScroll from './SetBackgroundOnScroll';
import NavbarLinks from './NavbarLinks';
import DrawerMenu from './DrawerMenu';
import sizes from 'theme/sizes';

import SearchIcon from 'components/icons/SearchIcon';
import { appLinks } from 'routes/routes';
import useSWR from 'swr';
import { getMenuItems, menuApiUrl } from 'api/menuApi';
import GearIcon from 'components/icons/GearIcon';
import Logo from 'components/Logo';

const Navbar: React.FC = (): JSX.Element => {
  const { data: menuItems } = useSWR(menuApiUrl.getItems, getMenuItems, {
    suspense: false,
    revalidateOnMount: true,
  });

  return (
    <SetBackgroundOnScroll>
      <AppBar
        elevation={0}
        position='fixed'
        sx={(theme: Theme) => ({
          pl: 1,
          zIndex: theme.zIndex.drawer + 1,
          transition: 'all 200ms linear',
          px: sizes.containerPX,
        })}
      >
        <Grid container sx={{ py: 3 }} display='flex' flexDirection='row' alignItems='center'>
          <Grid
            item
            xs
            sx={{
              display: { xs: 'flex', lg: 'none' },
            }}
          >
            <DrawerMenu drawerItems={menuItems} />
          </Grid>
          <Grid
            item
            xs
            lg={10}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: { xs: 'center', lg: 'flex-start' },
            }}
          >
            <Stack direction='row' spacing={4} alignItems='center'>
              <Logo />
              <NavbarLinks items={menuItems} />
            </Stack>
          </Grid>
          <Grid item xs display='flex' flexDirection='row' justifyContent='flex-end'>
            <Stack direction='row' spacing={{ xs: 1, lg: 2 }} alignItems='center'>
              <IconButton
                component={NavLink}
                to={appLinks.search.link}
                sx={{
                  p: 0,
                }}
              >
                <SearchIcon
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                />
                <SearchIcon
                  fontSize='large'
                  sx={{
                    display: { xs: 'none', md: 'block' },
                  }}
                />
              </IconButton>
              <IconButton
                component={NavLink}
                to={appLinks.settings.link}
                sx={{
                  p: 0,
                }}
              >
                <GearIcon
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                />
                <GearIcon
                  fontSize='large'
                  sx={{
                    display: { xs: 'none', md: 'block' },
                  }}
                />
              </IconButton>
              <NavbarDropdown />
            </Stack>
          </Grid>
        </Grid>
      </AppBar>
    </SetBackgroundOnScroll>
  );
};

export default Navbar;
